@import "../../../src/styles/variables";
@import "../../../src/styles/buttons";
@import "../../components/bot-inputs/style.scss";

.container {
  max-width: 700px;
  margin: 40px auto;
}

.full {
  width: 100%;
  display: block;
  float: left;
  margin-top: 77px;
}

.banner {
  margin: 0 0 30px 55px;
}

.banner img {
  width: 400px;
}

.privacy-policy {
  color: $pure-white !important;
  opacity: 0.5;
  margin-left: 40px;
  margin-top: 1em;
  font-size: 14px;
}

.message-time {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 10px;
  color: $medium-gray;
}

.message-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-tooltip {
  max-width: calc(100% - 40px);
  background-color: #3a4156e3;
  color: white;
  border-radius: 3px;
  position: absolute;
  font-size: 14px;
  padding: 2px 4px;
  line-break: anywhere;
  bottom: -20px;
  z-index: 100;
}

.message-tooltip.bot {
  left: 70px;
}

.message-tooltip.user {
  right: 20px;
}

.option-confirmar {
  background-color: $primary-color;
  color: $pure-white;
}

.customButtons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
}

.options-container {
  display: grid;
  gap: 10px;
  margin-left: 50px;
}

.message-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
}

.message-row.bot {
  justify-content: flex-start;
  position: relative;
}

.message-row.user {
  justify-content: flex-end;
  position: relative;
}

.message-bubble {
  padding: 1px 20px;
  border-radius: 10px;
  font-size: 16px;
  max-width: 65%;
  position: relative;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
  margin-left: 10px;
}

.message-bubble--component {
  padding: 16px 0;
}

.message-bubble.bot {
  background-color: $pure-white;
  color: $charcoal-gray;
  border-radius: 10px 10px 10px 0;
  text-align: left;
}

.codigo-pix {
  width: 100%;
  word-break: break-word;
  white-space: pre-wrap;
}

.message-bubble.user {
  max-width: calc(100% - 90px);
  background-color: rgba(0,0,0,0.2);
  color: $pure-white;
  border-radius: 10px 10px 0 10px;
  text-align: right;
  position: relative;
}

.message-component {
  background-color: white;
}

.bot-logo {
  width: 40px;
  display: flex;
  justify-content: center;
}

.space {
  width: 0;
  margin-right: 37px;
}

.last-logo img {
  width: 30px;
  margin-top: 22px;
}
img.card-payment {
  width: 100%;
  max-width: 300px;
  border-radius: 10px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media (max-width: 599px) {
  .container {
    max-width: 100%;
    padding: 0;
  }
  .messages {
    padding: 0 20px;
  }

  .options-container {
    margin: 0 20px;
  }

  .banner {
    margin: 0 20px 30px 20px;
  }
  .banner img {
    width: 100%;
  }

  .message-bubble {
    max-width: 100%;
  }

  .message-bubble.bot {
    max-width: calc(100% - 90px);
    display: inline-block;
  }
}

@media (max-width: 767px) {
  .customButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .customButtons .option-button {
    flex: 1 1 48%;
    margin-bottom: 10px;
  }
}
