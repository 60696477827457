@import "../../../src/styles/variables";

.carousel-button {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  font-size: 20px;
  margin: 0 5px;
  border-radius: 20px;
  color: $charcoal-gray;
  background-color: $pure-white !important;
}

.carousel-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  font-size: 20px;
  margin-right: 10px;
  border-radius: 20px;
  background-color: #FFFFFF10;
}

.carousel-item.card-pagamentade .carousel-button, .carousel-item.card-pagamentade .services-carousel-button  {
  background-image: url("../../../src/assets/icons/tag-pagametade.svg");
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 60%;
}

.carousel-container {
  padding: 0 0 30px 50px;
}
.option {
  font-size: 18px;
  font-weight: bold;
}
.slick-prev,
.slick-next {
  z-index: 1;
}
.description {
  font-size: 18px;
  max-width: 80%;
  margin: 10px auto;
  color: $charcoal-gray;
  font-weight: normal;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
}

.slick-prev {
  left: -20px;
  top: 45%;
}

.slick-next {
  right: 10px;
  top: 45%;
}

.slick-disabled{
  filter: grayscale(1) brightness(1.5);
}

.slick-arrow {
  background-size: 20px !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  border-radius: 6px;
  background-color: #5451fb;
  transition: filter 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  background-image: none !important;
}

.slick-arrow svg{
  width: 25px !important;
  height: 25px !important;
}

.custom-prev-arrow {
  transform: rotate(180deg) !important;
}

.custom-next-arrow {
  transform: rotate(0) !important;

}

.slick-next:hover, .slick-next:focus {
  background-image: none!important;
  background-color: #5451fb;
}
.slick-prev:hover, .slick-prev:focus {
  background-image: none !important;
  background-color: #5451fb;
}

.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
  filter: brightness(0.8);
}

.slick-disabled{
  background-color: #8a8a8a !important;
}
.slick-prev:before,
.slick-next:before {
  display: none;
}
.choose-button {
  opacity: 0.5;
  transition: opacity 0.3s;
}

.choose-button.active {
  opacity: 1;
  font-weight: bold;
  background-color: $primary-color;
  color: $pure-white;
}

@media (max-width: 599px) {
  .carousel-button {
    margin: 0;
    width: calc(100vw - 100px);
  }
  .carousel-container {
    padding: 0;
  }
  .slick-prev {
    left: 30px;
  }
  .slick-next {
    right: 30px;
  }
  .slick-slide {
    margin-right:10px;
  }
  .slick-list{
    margin-left: 50px;
  }
}

@media (max-width: 390px) {
  .carousel-button {
    width: calc(100vw - 80px);
  }
  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
  .slick-list{
    margin-left: 40px;
  }
}
@media (max-width: 320px) {
  .carousel-button {
    margin: 0;
    width: calc(100vw - 60px);
  }
  .slick-next {
    right: 5px;
  }
  .slick-slide {
    margin-right:5px;
  }
  .slick-list{
    margin-left: 30px;
  }

}