:root {
  --primary-color: #5451fb;
  --primary-accent-color: #4141ac;
  --pure-white: #ffffff;
  --charcoal-gray: #2f324a;
  --light-sky-blue: #b4d0f0;
  --medium-gray: #888888;
  --stormy-blue: #3a4156;
  --translucent-white: #ffffff1f;
  --blush-white: #f8f0f0;
  --error-color: #f14668;
  --accent-blue: #0131ff;
}

$primary-color: var(--primary-color);
$primary-accent-color: var(--primary-accent-color);
$pure-white: var(--pure-white);
$charcoal-gray: var(--charcoal-gray);
$light-sky-blue: var(--light-sky-blue);
$medium-gray: var(--medium-gray);
$stormy-blue: var(--stormy-blue);
$translucent-white: var(--translucent-white);
$blush-white: var(--blush-white);
$error-color: var(--error-color);
$accent-blue: var(--accent-blue);
