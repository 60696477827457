@import "../../styles/variables";

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: #f5f5f5;
  font-size: 2rem;
  color: #333;
}

.error-page__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.error-page__text {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
}

.error-page__contrast {
  font-size: 1.2rem;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2;
  color: $stormy-blue;
  margin: 0 0 2rem 0;
}

.error-page__list {
  margin: 0;
  padding-left: 32px;
}

.error-page__list li::marker {
  font-size: 1rem;
  color: $accent-blue;
}

.error-page__link {
  text-decoration: none;
  font-weight: 700;
  color: $accent-blue;
  font-size: 1.1rem;
}

.error-page__grid {
  width: 50%;
}

.error-page__img-container {
  display: flex;
}

.error-page__img {
  max-width: 290px;
}

.error-page__img-error {
  width: 230px;
}

.error-page__infos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  max-width: 60%;
  margin-left: auto;
}

@media screen and (max-width: 768px) {
  .error-page {
    height: 100vh;
  }

  .error-page__container {
    margin-top: 109px;
    flex-direction: column;
  }

  .error-page__grid {
    margin-top: 10px;
    width: 100%;
  }

  .error-page__infos {
    max-width: 80%;
    align-items: center;
    margin: auto;
  }

  .error-page__contrast,
  .error-page__text {
    text-align: center;
  }

  .error-page__list-item {
    height: 1.5rem;
  }

  .error-page__link {
    font-size: 0.9rem;
  }

  .error-page__img {
    max-width: 170px;
    margin: auto;
    margin-top: 32px;
  }

  .error-page__img-error {
    width: 150px;
    margin: 16px 0;
  }
}
