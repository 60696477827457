@import "../styles/variables";

button {
  color: rgb(255, 255, 255);
  background-color: $primary-color;
  border-color: $primary-color;
  width: 100%;
  max-width: 500px;
  cursor: pointer;
  height: 50px;
  font-weight: 700;
  border-radius: 10px;
  border: 0.125rem solid transparent;
  padding: 1rem;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

button:hover {
  background-color: $primary-accent-color;
}

.secondary {
  background-color: transparent;
  border: 2px solid $pure-white;
  color: $pure-white;
}

.tertiary {
  background-color: $pure-white;
  color: $primary-color;
}

.secondary:hover {
  background-color: $translucent-white;
}

.tertiary:hover {
  background-color: $blush-white;
}

.option-button.btwhatsapp {
  background: white;
  color: rgb(84, 81, 251);
  justify-content: center;
  padding: 0 20px;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  border-color: #fff;
}
.option-button.btwhatsapp figure {
  margin: 0;
  position: relative;
  width: 30px;
  margin-right: 10px;
}
.option-button.btwhatsapp figure img {
  width: 100%;
}
.option-button.btwhatsapp span {
  padding: 0;
  margin: 0;
}


@media (max-width: 599px) {


}
