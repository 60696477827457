@import "../../styles/variables";

.services-carousel-button {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px !important;
  font-size: 20px;
  margin-right: 10px;
  color: $charcoal-gray;
  background-color: $pure-white !important;
  border-radius: 20px;
}

.slick-list {
  height: 300px;
}
.carousel-container {
  padding: 0 0 0 40px;
}
.options-title {
  font-size: 24px;
  font-weight: bold;
}
.slick-prev,
.slick-next {
  z-index: 1;
}
.description-options {
  font-size: 14px;
  color: $charcoal-gray;
  font-weight: normal;
}
.services-carousel-button:hover {
  background-color: $pure-white;
}
.slick-prev,
.slick-next {
  width: 30px !important;
  height: 30px !important;
}
.link-highlight {
  color: $primary-color;
  font-weight: bold;
}
.custom-prev-arrow {
  background-image: url("../../../src/assets/icons/arrow.png") !important;
  background-size: cover !important;
  width: 30px;
  height: 30px;
  transform: rotate(0) !important;
}

.custom-next-arrow {
  background-image: url("../../../src/assets/icons/arrow.png") !important;
  background-size: cover !important;
  width: 30px;
  height: 30px;
  transform: rotate(180deg) !important;
}

.services-carousel-image-container {
  display: contents;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.slick-prev:before,
.slick-next:before {
  display: none;
}

.carousel-icon {
  font-size: 12px;
}

@media (max-width: 599px) {
  .services-carousel-button {
    margin: 0;
    width: calc(100vw - 100px);
  }
  .carousel-container {
    padding: 0;
  }
  .slick-prev {
    left: 30px;
  }
  .slick-next {
    right: 30px;
  }
  .slick-slide {
    margin-right:10px;
  }
  .slick-list{
    margin-left: 50px;
  }
}

@media (max-width: 390px) {
  .services-carousel-button {
    width: calc(100vw - 80px);
  }
  .slick-prev {
    left: 20px;
  }
  .slick-next {
    right: 20px;
  }
  .slick-list{
    margin-left: 40px;
  }
}
@media (max-width: 320px) {
  .services-carousel-button {
    margin: 0;
    width: calc(100vw - 60px);
  }
  .slick-next {
    right: 5px;
  }
  .slick-slide {
    margin-right:5px;
  }
  .slick-list{
    margin-left: 30px;
  }

}