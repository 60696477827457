@import "../../styles/variables";

.payment-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 82%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  position: relative;
}

.close {
  margin-top: -20px;
}

.credit-card {
  width: 100%;
  height: 230px;
  perspective: 1000px;
  position: relative;
  margin-bottom: 20px;
}

.card-front,
.card-back {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-color: $charcoal-gray;
  color: $pure-white;
  box-sizing: border-box;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.6s;
}

.card-front {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: rotateY(0deg);
}

.card-back {
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: end;
  transform: rotateY(180deg);
}

.flipped .card-front {
  transform: rotateY(-180deg);
}

.flipped .card-back {
  transform: rotateY(0deg);
}

.text-number {
  font-size: 10px;
  color: $medium-gray;
}

.card-number {
  margin: 0px;
  font-size: 24px;
  letter-spacing: 3px;
  font-weight: bold;
}

.card-name,
.card-expiry,
.card-cvv {
  font-size: 16px;
}

.payment-form__input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid $medium-gray;
  outline: none;
  transition: 0.1s ease;
}

.payment-form__input--error {
  border: 1px solid $error-color;
}

.payment-form__message {
  min-height: 15px;
  font-size: 12px;
  color: $error-color;
  margin: 4px 0 4px 10px;
}

.form-row {
  display: flex;
  justify-content: space-between;
}

.form-row__input-wrapper {
  flex: 1;
}

.payment-form .form-row {
  display: flex;
  gap: 10px;
  justify-content: space-between;
}

.form-column {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.space-buttons {
  display: grid;
  width: 100%;
  gap: 10px;
}

.form-submit--disabled {
  cursor: not-allowed;
  pointer-events: none;
  filter: brightness(0.5);
}

.form-submit--unclickable {
  pointer-events: none;
  cursor: not-allowed;
}

.card-cvv {
  font-size: 14px;
  width: 80%;
  padding: 10px 0;
  float: right;
  text-align: right;
  letter-spacing: 4px;
  background-color: $pure-white;
  font-weight: bold;
  color: $charcoal-gray;
}

.barra-black {
  width: 100%;
  height: 40px;
  background-color: black;
  float: left;
  margin: 10px 0 20px 0;
}

.logo-cvv {
  margin-top: 50px;
}

.position-img {
  align-items: end;
}

.payment-image {
  width: 250px;
}
