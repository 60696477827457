@import "/src/styles/_variables.scss";
body {
  margin: 0;
  padding: 0;
  background-color: $stormy-blue;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  font-weight: 400;
}

a {
  color: inherit;
}

b{
  font-weight: 700;
}
