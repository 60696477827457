@import "../../styles//variables";

.loading-dots {
  margin: auto;
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, #ffffff 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}
@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}

.loading-dots--color-primary {
  background: radial-gradient(
      circle closest-side,
      $primary-color 90%,
      #007bff00
    )
    0 / calc(100% / 3) 100% space;
}

.loading-dots--color-white {
  background: radial-gradient(
      circle closest-side,
      white 90%,
      #007bff00
    )
    0 / calc(100% / 3) 100% space;
}

.loading-dots--small {
  width: 35px;
}

.loading-dots--medium {
  width: 60px;
}

.loading-dots--large {
  width: 80px;
}

// @import "../../styles//variables";

// .loading-dots {
//   margin: auto;
//   width: 55px;
//   aspect-ratio: 4;
//   background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
//   background-size: calc(100% / 3) 100%;
//   animation: l7 1s infinite linear;
// }

// @keyframes l7 {
//   33% {
//     background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
//   }
//   50% {
//     background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
//   }
//   66% {
//     background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
//   }
// }

// .loading-dots--color-primary {
//   --_g: no-repeat radial-gradient(circle closest-side, #5451fb 90%, #0000);
// }

// .loading-dots--color-white {
//   --_g: no-repeat radial-gradient(circle closest-side, #ffffff 90%, #0000);
// }

// .loading-dots--small {
//   transform: scale(0.6);
// }

// .loading-dots--medium {
//   transform: scale(0.8);
// }

// .loading-dots--large {
//   transform: scale(1);
// }

