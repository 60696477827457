@import "../../styles/variables";
header {
  padding: 20px 110px;
  position: fixed;
  width: 100%;
  z-index: 111;
  box-sizing: border-box;
}
.header-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $stormy-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 1000;
}

.menu-icon {
  margin-top: 20px;
  right: 0;
  position: relative;
  z-index: 1111111;
  float: right;
}

.header-menu.open {
  opacity: 1;
  visibility: visible;
}

.menu-list {
  list-style: none;
  text-align: center;
  padding: 0;
}

.menu-list li {
  margin: 20px 0;
}

.menu-list li a {
  color: rgb(3, 200, 207);
  text-decoration: none;
  font-size: 24px;
}

.menu-list li a:hover {
  font-weight: bold;
  color: $pure-white;
}

@media (max-width: 599px) {
  header {
    justify-content: space-between;
    align-items: flex-start;
    display: flex;
    box-sizing: border-box;
    padding: 20px 20px;
  }
}
