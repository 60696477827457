.input-area {
  display: flex;
  padding: 0 0 0 50px;
  gap: 10px;
  flex-direction: column;
  margin-top: 10px;
}

.input-container {
  display: flex;
  width: 350px;
  float: left;
  position: relative;
  border-radius: 10px;
  background-color: $pure-white;
  padding: 14px 14px 7px 14px;
  border-radius: 10px 10px 0 0;
}

.input-container {
  form {
    width: 100%;
    display: flex;
    position: relative;
  }
}

.input-container input {
  width: calc(100% - 30px);
  padding: 18px 44px 18px 14px;
  border: 2px solid $primary-color;
  border-radius: 7px;
  font-size: 16px;
  font-family: "Montserrat", sans-serif;
}

.input-container__error input {
  border: 2px solid $error-color !important;
}

.input-arrow:hover {
  fill: $primary-color;
}

.input-container button:hover {
  background-color: $primary-color;
}

.input-arrow {
  position: absolute;
  right: 15px;
  font-size: 24px;
  cursor: pointer;
  fill: $primary-color;
  margin-top: 17px;
}

.input-arrow__disabled {
  opacity: 0.6;
  cursor: default;
  pointer-events: none;
}

.input-hint {
  width: 354px;
  font-size: 0.75rem;
  color: $charcoal-gray;
  margin-top: 8px;
  text-align: left;
  background: $pure-white;
  padding-left: 13px;
  padding: 0px 10px 13px 14px;
  margin-top: -12px;
  border-radius: 0 0 10px;
}

.input-hint__error {
  color: $error-color;
}

.input-container input:focus {
  outline: none;
}

@media (max-width: 599px) {
  .input-hint,
  .input-container {
    box-sizing: border-box;
    width: 100%;
  }
}
